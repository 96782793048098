import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import { Modal, Button } from 'shared-components'

const dateRangeErrorModalMessages = defineMessages({
  USER_SEARCH_DATE_RANGE_ERROR_TITLE: {
    id: 'USER_SEARCH_DATE_RANGE_ERROR_TITLE',
    defaultMessage: 'Invalid Dates'
  },
  USER_SEARCH_DATE_RANGE_ERROR_OK: {
    id: 'USER_SEARCH_DATE_RANGE_ERROR_OK',
    defaultMessage: 'OK'
  }
})

class DateRangeErrorModal extends Component {
  render() {
    const { onOk, intl, errors } = this.props
    return (
      <Modal
        title={intl.formatMessage(
          dateRangeErrorModalMessages.USER_SEARCH_DATE_RANGE_ERROR_TITLE
        )}
        visible={errors && errors.length > 0}
        onCancel={onOk}
        footer={
          <Button
            key="back"
            onClick={onOk}
            style={{ backgroundColor: '#503191', color: '#ffffff' }}
          >
            {intl.formatMessage(
              dateRangeErrorModalMessages.USER_SEARCH_DATE_RANGE_ERROR_OK
            )}
          </Button>
        }
      >
        {errors.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </Modal>
    )
  }
}

export default injectIntl(DateRangeErrorModal)
