import React from 'react'
import { Helmet } from 'isomorphic-tools'

const DownloadErrorHead = () => {
  return (
    <Helmet>
      <title>No Records Found | Materials Direct</title>
      <meta name="description" content="No Records Found" />
    </Helmet>
  )
}

export default DownloadErrorHead
