import React from 'react'
import { Helmet } from 'isomorphic-tools'

const FourZeroFourHead = () => {
  return (
    <Helmet>
      <title>404: Page Not Found | Materials Direct</title>
      <meta name="description" content="404: Page Not Found" />
    </Helmet>
  )
}

export default FourZeroFourHead
