import React from 'react'
import { Field } from 'react-final-form'

import { DatePicker, FormItem } from 'shared-components'

const RenderDatePicker = ({
  input: { value, ...input },
  placeholder,
  wrapperClass
}) => (
  <FormItem className={wrapperClass}>
    <DatePicker
      placeholder={placeholder}
      size="large"
      value={value || null}
      format="MM/DD/YYYY"
      style={{ width: '100%' }}
      {...input}
    />
  </FormItem>
)

const DatePickerFormField = props => (
  <Field component={RenderDatePicker} {...props} />
)

export default DatePickerFormField
