import React, { useEffect, useState } from 'react'
import { withApollo, compose } from 'react-apollo'
import { Row, Col, Alert } from 'shared-components'
import classNames from 'classnames/bind'
import styles from './Dashboard.scss'
import DefaultLayout from 'layouts/DefaultLayout'
import AdminApps from './components/AdminApps'
import QuickAccessBox from './components/QuickAccessBox'
import {
  saveQuickAccessApps,
  loadQuickAccessApps,
  saveActiveApps,
  saveAzureEmail
} from 'utils/localStorageUtils'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'
import GetInternalAppMetaData from 'apollo/queries/GetInternalAppMetaData'
import LoadingSkelton from 'components/LoadingSkelton'
import UPDATE_MUTATION from 'apollo/mutations/UpdateLogin'
import { useLocation, useHistory  } from 'react-router-dom'

const cx = classNames.bind(styles)

const Dashboard = ({ client }) => {
  const [listOfApps, setAppsList] = useState([])
  const [quickAccessApps, setQuickAcessApps] = useState([])
  const [azureemail, setAzureemail] = useState('')
  const [noAccess, setNoAccess] = useState(false)
  const [clickedApp, setClickedApp] = useState('')
  const [ lockedUsers, setShow90Days] = useState([])
  const [ singleAppError, setSingleAppError] = useState(false)

  const [singleAppAccess, setSingleAppAccess] = useState(false)

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const history = useHistory()
  const { pathname } = history.location;
  const show90DaysLocked = (appMetaDataList) => {
    let users = []
    appMetaDataList.map((apps) => 
      apps.altPersonas.map((user) => 
        (user.status === 'E' && !users.includes(user.logonId)) ? 
          users = [...users, user.logonId] : ''
      ) 
    )
    users.length > 0 && setShow90Days(users)
  }


  const getAppMetaData = async () => {
    try {
      const { data } = await client.query({
        query: GetInternalAppMetaData
      })

      if (data && data.getInternalAppMetaData) {
        const {
          getInternalAppMetaData: { adEmailId, appMetaDataList }
        } = data
        setAzureemail(adEmailId)
        saveAzureEmail(adEmailId)
        setAppsList(appMetaDataList)
        const currentApps = loadQuickAccessApps(adEmailId)
        show90DaysLocked(appMetaDataList)
        compareAppListAndRemoveLockedAccess(appMetaDataList, currentApps.activeApps, adEmailId)
        if (appMetaDataList && appMetaDataList.length === 1) {
          // Access is only for one APP
          if (
            appMetaDataList[0].altPersonas &&
            appMetaDataList[0].altPersonas.length === 1 &&
            (appMetaDataList[0].altPersonas[0].status === 'A' || appMetaDataList[0].appName === 'B2B Console')
          ) {
            setSingleAppAccess(true)

            redirectToApp(
              appMetaDataList[0].altPersonas[0].userId,
              appMetaDataList[0].appName,
              appMetaDataList[0].contextPath,
              adEmailId
            )
          } else if(appMetaDataList[0].altPersonas &&
            appMetaDataList[0].altPersonas.length === 1 &&
            (appMetaDataList[0].altPersonas[0].status === 'L' || appMetaDataList[0].altPersonas[0].status === 'E')) {
            setSingleAppError(true)
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getAppMetaData()
  }, [])

  useEffect(() => {
    const nextAppToAccess = searchParams && searchParams.get('nextAccess')
    const currentApps = loadQuickAccessApps(azureemail)
   
    if (nextAppToAccess && listOfApps && currentApps.activeApps) {
      const appIsThere = currentApps.activeApps.filter((appDetails) => {
        return appDetails.appName.toLowerCase().replace(/\s/g,'') === nextAppToAccess.toLowerCase().replace(/\s/g,'')
      })

      if(appIsThere && appIsThere.length === 0) {
        setNoAccess(true)
      } else {
        setNoAccess(false)
      }
    }
  }, [searchParams, listOfApps])

  useEffect(() => {
    const apps = loadQuickAccessApps(azureemail)
    const quickAccessApps = compareAppListAndGetQuickAccessApp(listOfApps,
      apps && apps.quickAccessApps ? apps.quickAccessApps : []
    )
    setQuickAcessApps(quickAccessApps)
    setFavoriteState(azureemail, listOfApps)
  }, [listOfApps, azureemail])

  const setAsFavorite = (
    isFavoriteCurrentState,
    userName,
    userId,
    appName,
    contextPath,
    userLogonId,
    status
  ) => {
    const currentApps = loadQuickAccessApps(azureemail)
    const userIndex = findIndex(currentApps.quickAccessApps, {
      appName,
      userId
    })

    if (!isFavoriteCurrentState) {
      if (userIndex === -1) {
        currentApps.quickAccessApps.push({
          appName,
          userId,
          userName,
          contextPath,
          userLogonId,
          status
        })
      }
    } else {
      remove(currentApps.quickAccessApps, {
        appName,
        userId
      })
    }

    saveQuickAccessApps(currentApps.quickAccessApps, azureemail)

    const apps = loadQuickAccessApps(azureemail)
    const quicAccessApps = compareAppListAndGetQuickAccessApp(listOfApps,
       apps && apps.quickAccessApps ? apps.quickAccessApps : []
     )
    setQuickAcessApps(quicAccessApps)
    setFavoriteState(azureemail, listOfApps)
  }

  const compareAppListAndGetQuickAccessApp = (appList, quickAccessApps) => {
    let quickAccessAppList = []
    if (
      appList &&
      appList.length &&
      quickAccessApps &&
      quickAccessApps.length
    ) {
      //Check for all items in the quickAccessApps to see if it is in the app list
      quickAccessApps.forEach(quickAccessAppItem => {
        const userAppList = appList.find(allAppItem => {
          return allAppItem.appName === quickAccessAppItem.appName
        })

        if (userAppList) {
          const userRecord = userAppList.altPersonas.find(altPersonItem => {
            return altPersonItem.userId === quickAccessAppItem.userId
          })

          if (userRecord) {
            quickAccessAppItem.userName = userRecord.name ? userRecord.name : ''
            quickAccessAppItem.userLogonId = userRecord.logonId ? userRecord.logonId : ''
            quickAccessAppItem['status'] = userRecord.status ? userRecord.status : ''
            quickAccessAppList.push(quickAccessAppItem)
          }
        }
      })
      saveQuickAccessApps(quickAccessAppList, azureemail)
    }

    return quickAccessAppList
  }

  const compareAppListAndRemoveLockedAccess = (appList, activeApps, azureemail) => {
    let activeAppList = []
    if (
      appList &&
      appList.length &&
      activeApps &&
      activeApps.length
    ) {
      //Check for all items in the quickAccessApps to see if it is in the app list
      activeApps.forEach(activeAppItem => {
        const userAppList = appList.find(allAppItem => {
          return allAppItem.appName === activeAppItem.appName
        })

        if (userAppList) {
          const userRecord = userAppList.altPersonas.find(altPersonItem => {
            return altPersonItem.userId === activeAppItem.userId
          })
          if (userRecord && (userRecord.status === 'A' || userAppList.appName === 'B2B Console')) {
            activeAppItem.userName = userRecord.name ? userRecord.name : ''
            activeAppItem.userLogonId = userRecord.logonId ? userRecord.logonId : ''
            activeAppItem['status'] = userRecord.status ? userRecord.status : ''
            activeAppList.push(activeAppItem)
          }
        }
      })
      saveActiveApps(activeAppList, azureemail)
    }
  }

  const setFavoriteState = (azureemail, appList) => {
    const quickAccessApps = loadQuickAccessApps(azureemail)
    appList.forEach(appData => {
      appData.altPersonas.forEach(userDetails => {
        const indexInQuickAccess = findIndex(quickAccessApps.quickAccessApps, {
          appName: appData.appName,
          userId: userDetails.userId
        })

        if (indexInQuickAccess > -1) {
          userDetails.isFavorite = true
        } else {
          userDetails.isFavorite = false
        }
      })
    })
  }

  const setActiveApp = (userId, appName, azureemail, status) => {
    const currentApps = loadQuickAccessApps(azureemail) || []
    const appIndex = findIndex(currentApps.activeApps, {
      appName
    })

    if (appIndex === -1) {
      currentApps.activeApps.push({
        appName,
        userId,
        status: status ? status : ''
      })
    } else {
      currentApps.activeApps[appIndex].userId = userId
      currentApps.activeApps[appIndex].status = status
    }

    saveActiveApps(currentApps.activeApps, azureemail)
  }

  const redirectToApp = async(userId, appName, contextPath, azureemail, status) => {
    try {
      await client.mutate({
        mutation: UPDATE_MUTATION,
        variables: {
          participantId: userId
        }
      }) 
    } finally {
      setActiveApp(userId, appName, azureemail, status)
      const fullappName = `${window.location.origin}${contextPath}`
      window.name = appName
      window.location.href = fullappName
    }
  }

  const openApp = async(userId, appName, contextPath, isRedirect = false, status) => {
    try {
      await client.mutate({
        mutation: UPDATE_MUTATION,
        variables: {
          participantId: userId
        }
      }) 
    } finally {
      setActiveApp(userId, appName, azureemail, status)
      const fullappName = `${window.location.origin}${contextPath}`

      if (navigator && navigator.webdriver) {
        window.location.href = fullappName
      } else {
        window.open(fullappName, appName, '').focus() 
      }      
    }
  }

  const getAppIcon = appName => {
    const availableImages = ['ivd' ]
    const availableSVGImages = ['blockedaccountaffiliation', 'promotionalbundle', 'countrysettings', 'emprove','orgadmin','ordernotes','b2bconsole','configurator', 'ordersourcereporting','dpm']
    const genericInternalTool = [ ]
    if (
      availableImages.indexOf(
        appName
          .split(' ')
          .join('')
          .toLowerCase()
      ) > -1
    ) {
      return `/media/icons/${appName
        .split(' ')
        .join('')
        .toLowerCase()}.png`
    } else if (
      availableSVGImages.indexOf(
        appName
          .split(' ')
          .join('')
          .toLowerCase()
      ) > -1
    ) {
      return `/media/icons/${appName
        .split(' ')
        .join('')
        .toLowerCase()}.svg`
    } else if (genericInternalTool.indexOf(
      appName
        .split(' ')
        .join('')
        .toLowerCase()) > -1) {
          return `/media/icons/genericinternaltool.svg`
    } else {
      return '/media/icons/default.png'
    }
  }

const sortListOfApps =  listOfApps.sort((a,b) => (a.appName > b.appName) ? 1 : ((b.appName > a.appName) ? -1 : 0))
const AdminAppList = sortListOfApps.map((apps, index) => {
    if (apps && apps.appName && apps.altPersonas && apps.contextPath) {
      return (
        <AdminApps
          appName={apps.appName}
          contextPath={apps.contextPath}
          userList={apps.altPersonas}
          key={index}
          setAsFavorite={setAsFavorite}
          openApp={openApp}
          getAppIcon={getAppIcon}
          setNoAccess={setNoAccess}
          setClickedApp={setClickedApp}
        />
      )
    } else {
      return null
    }
  })

  const sortQuickAccessApps = quickAccessApps.sort((a,b) => (a.appName > b.appName) ? 1 : ((b.appName > a.appName) ? -1 : 0))
  const QuickAccessAppsList = sortQuickAccessApps.map((app, index) => {
    if (app && app.appName && app.userId && app.contextPath) {
      return (
        <QuickAccessBox
          appName={app.appName}
          userList={app.altPersonas}
          userName={app.userName}
          userId={app.userId}
          userLogonId={app.userLogonId}
          key={index}
          setAsFavorite={setAsFavorite}
          contextPath={app.contextPath}
          openApp={openApp}
          getAppIcon={getAppIcon}
          status={app.status}
          setNoAccess={setNoAccess}
          setClickedApp={setClickedApp}
        />
      )
    } else {
      
      return null
    }
  })
  
  if(singleAppError){
    return (
      <DefaultLayout>
        <div>
          {listOfApps[0].altPersonas[0].status === 'L' &&  <Alert
            className={cx('alert-error-warning')}
            message={`You do not have permission to access ${listOfApps[0].appName}.`}
            type="warning"
            closable
            showIcon
            />}
            {(lockedUsers && lockedUsers.length) ?  <Alert
            className={cx('alert-error-warning')}
            message={<>Your account {lockedUsers[0]} has been locked due to inactivity. Please contact <a className={cx('link')} href="mailto:webhelp@merckgroup.com"> Web Help</a></>}
            type="warning"
            closable
            onClose={() => {
              setShow90Days([])   
            }}
            showIcon
          /> : ''}
        </div>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      <div>
        {listOfApps && listOfApps.length ? (
          <>
             {noAccess &&  <Alert
              className={cx('alert-error-warning')}
              message={`You do not have permission to access ${searchParams.get('nextAccess') || clickedApp}.`}
              type="warning"
              closable
              onClose={() => {
                if(clickedApp){
                  setClickedApp('')
                  setNoAccess(false)
                } else {
                  const newLocation = {
                    pathname,
                    search: ''
                  }
                  history.push(newLocation)
                }              
              }}
              showIcon
              />}
              {(lockedUsers && lockedUsers.length) ?  <Alert
              className={cx('alert-error-warning')}
              message={<>Your account {lockedUsers.join(', ')} has been locked due to inactivity. Please contact <a className={cx('link')} href="mailto:webhelp@merckgroup.com"> Web Help</a></>}
              type="warning"
              closable
              onClose={() => {
                setShow90Days([])   
              }}
              showIcon
              /> : ''}
            {singleAppAccess ? (
              <LoadingSkelton />
            ) : (
              <>
                {QuickAccessAppsList && QuickAccessAppsList.length ? (
                  <Row className={cx('quick-access-container')}>
                    <Col xs={12} className={cx('header')}>
                      <h3>Quick Access</h3>
                    </Col>
                    {QuickAccessAppsList}
                  </Row>
                ) : null}

                <Row className={cx('all-apps-container')}>
                  <Col xs={12} className={cx('header')}>
                    <h3>All Admin Applications</h3>
                  </Col>
                  {AdminAppList}
                </Row>
              </>
            )}
          </>
        ) : (
          <Row className={cx('alert-container')}>
            <Alert
              className={cx('ant-alert-info-custom')}
              message={`Your profile is not assigned access to the internal apps maintained through this portal. Please contact the Web Help desk if you believe this is incorrect.`}
              type="info"
            />
          </Row>
        )}
      </div>
    </DefaultLayout>
  )
}

export default compose(withApollo)(Dashboard)
