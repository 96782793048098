import React from 'react'
import { Col } from 'shared-components'
import classNames from 'classnames/bind'
import styles from '../../Dashboard.scss'
import { Icon } from 'antd'
import { useHistory  } from 'react-router-dom'

const cx = classNames.bind(styles)

const AdminApps = ({
  appName,
  userList,
  appIconUrl,
  setAsFavorite,
  openApp,
  contextPath,
  getAppIcon,
  setNoAccess,
  setClickedApp
}) => {
  const history = useHistory()
  const { pathname } = history.location

  const redirectTo = (userId, appName, contextPath, status) => {
    if(status === 'A' || appName === 'B2B Console') {
      openApp(userId, appName, contextPath, false, status)
    } else if(status === 'L'){
      setClickedApp(appName)
      const newLocation = {
        pathname,
        search: ''
      }
      history.push(newLocation)
      setNoAccess(true)
    }
  }

  const ListOfUsers = userList.map((user, index) => {
    const userLogonId = user.logonId || ''
    return ( 
      <li key={index} className={cx(user.status === 'E' ? 'days-locked' : '')}>
        <p className={cx('user-name-app',user.status === 'E' ? 'days-locked' : '')} onClick={() => redirectTo(user.userId, appName, contextPath, user.status)}>
          {`${user.name} / ${userLogonId}`}
        </p>
        <Icon
          type="star"
          theme="filled"
          className={cx('favorite-icon', {
            'yellow-star': user.isFavorite
          })}
          onClick={() =>
            setAsFavorite(
              user.isFavorite,
              user.name,
              user.userId,
              appName,
              contextPath,
              userLogonId,
              user.status
            )
          }
        />
      </li>
    )
  })

  return (
    <Col xs={12} md={4} className={cx('app-box-container')}>
      <Col className={cx('app-box')} xs={12}>
        <Col xs={2} className={cx('app-icon')}>
          <img
            alt="Header logo"
            className="header__logo"
            src={getAppIcon(appName)}
          />
        </Col>
        <Col xs={10} className={cx('app-details-box')}>
          <p className={cx('app-name')}>{appName}</p>
          <div>
            <ul>{ListOfUsers}</ul>
          </div>
        </Col>
      </Col>
    </Col>
  )
}

export default AdminApps
