import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { withApollo, Query } from 'react-apollo'
import { compose } from 'ramda'
import ErrorBoundary from 'components/ErrorBoundary'
import GetInternalAppMetaData from 'apollo/queries/GetInternalAppMetaData.gql'
import { useLocalStorage } from 'react-storage'
import LoadingSkelton from 'components/LoadingSkelton'

const handleError = (error, resetStore) => {
  console.error(error)
  resetStore()
}

const RequireAuth = ({ client, children, location }) => {
  const accessToken = useLocalStorage('access-token')

  if (!accessToken) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    )
  }
  return (
    <ErrorBoundary
      onError={error => {
        handleError(error, client.resetStore)
      }}
    >
      <Query
        key={location.pathname}
        query={GetInternalAppMetaData}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (error) throw error
          if (loading) return <LoadingSkelton/>
          if (loading && data && !data.adEmailId) return null
          return children
        }}
      </Query>
    </ErrorBoundary>
  )
}

export default compose(withRouter, withApollo)(RequireAuth)
