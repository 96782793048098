import 'msEdge'
import 'polyfills'
import React from 'react'
import { render } from 'isomorphic-tools'
import { polyfill } from 'react-storage'

import 'styles/base.scss'
import AppContainer from 'components/AppContainer'

// dummy comment
polyfill()

window.name = 'dashboard'
window.opener = null

export default render({
  app: <AppContainer />,
  mountId: 'root',
  ssr: false
})
