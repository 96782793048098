import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import RequireAuth from 'components/RequireAuth'
import ScrollTop from 'components/ScrollTop'

import { Authenticate } from 'routes/Authentication'
import { AzureRedirectHandler } from 'routes/Authentication'
import { Dashboard } from 'routes/Dashboard'
import ThirdPartyTool from './ThirdPartyTool/ThirdPartyTool'

import FourZeroFourRoute from 'routes/FourZeroFour'
import ErrorRoute from './Error'

const Router = () => (
  <BrowserRouter basename="/">
    <ScrollTop>
      <Switch>
        <Route exact path="/" component={Authenticate} />

        <Route
          exact
          path="/auth/openid/return"
          component={AzureRedirectHandler}
        />

        <RequireAuth>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/SLReportingTool" component={ThirdPartyTool} />
            <Route exact path="/error" component={ErrorRoute} />
            <Route component={FourZeroFourRoute} />
          </Switch>
        </RequireAuth>
      </Switch>
    </ScrollTop>
  </BrowserRouter>
)

export default Router
