import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { Notification } from 'shared-components'

const uploadLink = createUploadLink({ uri: '/api' })

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem('access-token')
  return {
    headers: {
      ...headers,
      bearerToken: token ? `Bearer ${token}` : ''
    }
  }
})

const openNotification = (message, placement = 'topLeft') => {
  Notification.error({
    message,
    placement,
    duration: 3
  })
}

const extractErrorMessage  = error => {
  if (error && error.length) {
    try {
      return (
        error[0].extensions &&
        error[0].extensions.exception &&
        error[0].extensions.exception.data &&
        error[0].extensions.exception.data.errorKey
      )
    } catch (e) {
      return 'UNKNOWN'
    }
  } else {
    return 'UNKNOWN'
  }
}
const errorLink = onError(({ graphQLErrors}) => {
  const errorCode = extractErrorMessage(graphQLErrors)

  if (errorCode === 'USER_JWT_TOKEN_INVALID_OR_EXPIRED') {
    let message = 'Your session expired!'
    localStorage.removeItem('access-token')
    setTimeout(() => {
      window.location.href = '/'
    }, 500)
    openNotification(message)
  }
})

const httpErrLink = errorLink.concat(uploadLink)

export default new ApolloClient({
  link: authLink.concat(httpErrLink),
  cache: new InMemoryCache()
})
