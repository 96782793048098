import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Link } from 'react-router-dom'

import styles from './Card.scss'

const cx = classNames.bind(styles)

const Card = ({
  children,
  size,
  className,
  style,
  loading,
  link,
  linkClass
}) => {
  const cardContent = (
    <div
      className={cx('card', {
        [className]: className,
        [size]: size,
        loading: loading,
        link: link
      })}
      style={style}
    >
      {children}
    </div>
  )
  return link ? (
    <Link to={link} className={linkClass}>
      {cardContent}
    </Link>
  ) : (
    cardContent
  )
}

Card.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['large']),
  className: PropTypes.string,
  style: PropTypes.object,
  loading: PropTypes.bool,
  link: PropTypes.string,
  linkClass: PropTypes.string
}

export default Card
