import React, { useEffect } from 'react'
import AzureRedirectURLQuery from 'apollo/queries/AzureRedirectURLQuery.gql'
import { withApollo, compose } from 'react-apollo'
import LoadingSkelton from 'components/LoadingSkelton'

const Login = ({ client }) => {
  const handleLoginSubmit = async () => {
    try {
      const { data } = await client.query({
        query: AzureRedirectURLQuery,
        variables: {
          returnUrl: `${window.location.origin}/auth/openid/return`
        }
      })
      if (data) {
        const { azureRedirectURL } = data
        if (azureRedirectURL && azureRedirectURL.url) {
          await window.location.replace(azureRedirectURL.url)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleLoginSubmit()
  }, [])

  return <LoadingSkelton />
}
export default compose(withApollo)(Login)
