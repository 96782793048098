import find from 'lodash/find'
import findIndex from 'lodash/findIndex'

export const saveBearerToken = token => {
  localStorage.setItem('access-token', token)
}

export const loadBearerToken = () => {
  const azureEmail = window.localStorage.getItem('azureEmail')

  if (azureEmail) {
    return localStorage.getItem('access-token')
  }

  return ''
}
export const removeBearerToken = () => {
  localStorage.removeItem('access-token')
}

export const saveAzureEmail = email => {
  localStorage.setItem('azureEmail', email)
}

export const loadAzureEmail = () => {
  return localStorage.getItem('azureEmail')
}

export const removeAzureEmail = () => {
  localStorage.removeItem('azureEmail')
}

export const loadAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const loadLogonId = () => {
  const bearerToken = window.localStorage.getItem('access-token')
  const azureEmail = window.localStorage.getItem('azureEmail')
  let logonId = ''

  if (bearerToken && azureEmail) {
    //app is loaded from the dashboard local storage will have azureEmail field
    const currentApps = window.localStorage.getItem('quickAccess') || []
    const slUserApp = find(currentApps, { azureemail: azureEmail })

    if (slUserApp) {
      const orgAdminUser = find(slUserApp.activeApps || [], {
        appName: 'Order Source Report'
      })

      if (orgAdminUser && orgAdminUser.userId) {
        logonId = orgAdminUser.userId
      }
    }
  }

  return logonId
}

/*
Following is the structure of quickAccess 
Each user will have list of favorite apps in quickAccessApps
activeApps represents the userId which is active  for the selected app
in future we will have to encrypt the user id before saving
[
   {
     azureemail: 'akambham@global.sial.com',
     quickAccessApps: [
       {
         appName: 'dpm',
         userId: '3b45c520-ad83-11e9-a88e-e1f365b96365',
         userName: 'Arun'
       }
     ],
     activeApps: [
       {
        appName: 'dpm',
        userId: '3b45c520-ad83-11e9-a88e-e1f365b96365'
     }
    ]
   }
 ]
 */

export const saveActiveApps = (activeApps, azureemail) => {
  const currentApps = localStorage.getItem('quickAccess') || []
  const userIndex = findIndex(currentApps, { azureemail })

  if (userIndex > -1) {
    currentApps[userIndex].activeApps = activeApps
  } else {
    currentApps.push({
      azureemail,
      quickAccessApps: [],
      activeApps
    })
  }
  localStorage.setItem('quickAccess', currentApps)
}

export const saveQuickAccessApps = (quickAccessApps, azureemail) => {
  const currentApps = localStorage.getItem('quickAccess') || []
  const userIndex = findIndex(currentApps, { azureemail })

  if (userIndex > -1) {
    currentApps[userIndex].quickAccessApps = quickAccessApps
  } else {
    currentApps.push({
      azureemail,
      quickAccessApps,
      activeApps: []
    })
  }
  localStorage.setItem('quickAccess', currentApps)
}

export const loadQuickAccessApps = azureemail => {
  const currentApps = localStorage.getItem('quickAccess') || []
  const slUserApp = find(currentApps, { azureemail })

  if (!slUserApp) {
    currentApps.push({
      azureemail,
      quickAccessApps: [],
      activeApps: []
    })

    localStorage.setItem('quickAccess', currentApps)
  }

  return find(localStorage.getItem('quickAccess') || [], { azureemail })
}

export const removeQuickAccessApps = () => {
  localStorage.removeItem('quickAccess')
}
