import React from 'react'
import classNames from 'classnames/bind'
import styles from './LoadingSkelton.scss'
import LoadingBox from 'components/LoadingBox'
import { Col, Row } from 'shared-components'

const cx = classNames.bind(styles)
const LoadingSkelton = () => {
  

  return (
    <div className={cx('c')}>
      <Row className={cx('frequent-app-title')}>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '24px' }}
            className={'test'}
          />
        </Col>
      </Row>

      <Row className={cx('frequent-apps')}>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '88px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '88px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '88px' }}
            className={'test'}
          />
        </Col>
      </Row>

      <Row className={cx('frequent-app-title')}>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '24px' }}
            className={'test'}
          />
        </Col>
      </Row>

      <Row className={cx('frequent-apps')}>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
        <Col xs={4}>
          <LoadingBox
            style={{ width: '100%', height: '160px' }}
            className={'test'}
          />
        </Col>
      </Row>
    </div>
  )
}
export default LoadingSkelton
