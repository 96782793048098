import React from 'react'
import classNames from 'classnames/bind'
import styles from './LoadingBox.scss'
const cx = classNames.bind(styles)

const LoadingBox = ({ className, style, ...boxProps }) => {
  return (
    <div
      className={cx('placeholder-item', className && className)}
      style={style}
      {...boxProps}
    />
  )
}

export default LoadingBox
