import React  from 'react'
import { Col } from 'shared-components'
import classNames from 'classnames/bind'
import styles from '../../Dashboard.scss'
import { useHistory  } from 'react-router-dom'

const cx = classNames.bind(styles)

const QuickAccessBox = ({
  appName,
  userName,
  userId,
  userLogonId,
  setAsFavorite,
  openApp,
  contextPath,
  getAppIcon,
  status,
  setClickedApp,
  setNoAccess,
}) => {
  const history = useHistory()
  const { pathname } = history.location
  const redirectTo = (userId, appName, contextPath) => {
    if(status === 'A' || appName === 'B2B Console') {
      openApp(userId, appName, contextPath, false, status)
    } else if(status === 'L'){
      setClickedApp(appName)
      const newLocation = {
        pathname,
        search: ''
      }
      history.push(newLocation)
      setNoAccess(true) 
    }
  }

  return (
    <Col xs={12} md={4} className={cx('app-box-container')}>
    <Col className={cx('app-box')} xs={12}>
    <Col xs={2} className={cx('app-icon')}>
      <img
        alt="Header logo"
        className="header__logo"
        src={getAppIcon(appName)}
      />
    </Col>
    <Col xs={10} className={cx('app-details-box')}>
      <p className={cx('app-name')}>{appName}</p>
      <div>
        <ul>
        <li >
        <p className={cx('user-name-app',status === 'E' ? 'days-locked' : '')} onClick={() => redirectTo(userId, appName, contextPath, status)}>
          {`${userName} / ${userLogonId}`}
        </p>
        </li>
        </ul>
      </div>
    </Col>
  </Col>
    </Col>
  )
}

export default QuickAccessBox
