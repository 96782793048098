import React, { useEffect, useState } from 'react'
import GetBearerTokenQuery from 'apollo/queries/GetBearerTokenQuery'
import { withApollo, compose } from 'react-apollo'
import { saveBearerToken } from 'utils/localStorageUtils'
import { useHistory } from 'react-router-dom'
import DefaultLayout from 'layouts/DefaultLayout'
import LoadingSkelton from 'components/LoadingSkelton'
import { Row, Button, Alert } from 'shared-components'
import styles from './AzureRedirectHandler.scss'
import classNames from 'classnames/bind'
import {extractErrorMessage, getErrorMessage } from 'utils/extractErrorMessage'
import AzureRedirectURLQuery from 'apollo/queries/AzureRedirectURLQuery.gql'

const cx = classNames.bind(styles)
const AzureRedirectHandler = ({ location, client, history }) => {
  const queryParams = new URLSearchParams(location.search)
  const routerHistory = useHistory()
  const queryGen = {
    code: queryParams.get('code') || '',
    state: queryParams.get('state') || '',
    session_state: queryParams.get('session_state') || ''
  }
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const getToken = async () => {
    try {
      setLoading(true)
      setHasError(false)
      const { data } = await client.query({
        query: GetBearerTokenQuery,
        variables: queryGen
      })

      if (data) {
        const { getBearerToken } = data
        if (getBearerToken && getBearerToken['access_token']) {
          saveBearerToken(getBearerToken['access_token'])
          routerHistory.push('/dashboard')
        } else {
          routerHistory.push('/dashboard')
        }
        setLoading(false)
      }
    } catch (error) {
      const errorCode = extractErrorMessage(error.graphQLErrors)
      const errorMessage = getErrorMessage(errorCode)
      setErrorMessage(errorMessage)
      setHasError(true)
      setLoading(false)
    }
  }
  const handleRetryLogin = async () => {
    try {
      setLoading(true)
      const { data } = await client.query({
        query: AzureRedirectURLQuery,
        variables: {
          returnUrl: `${window.location.origin}/auth/openid/return`
        }
      })
      if (data) {
        const { azureRedirectURL } = data
        if (azureRedirectURL && azureRedirectURL.url) {
          window.location.replace(azureRedirectURL.url)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getToken()
  }, [
    queryParams.get('code'),
    queryParams.get('session_state'),
    queryParams.get('state')
  ])

  return(
    <>
    {loading && <LoadingSkelton />}
    {!loading && hasError && 
    (
      <DefaultLayout>
      <Row className={cx('alert-container')}>
        <Alert
              className={cx('ant-alert-info-custom')}
              message={errorMessage}
              type="info"
            />
      </Row>
      <Row className={cx('alert-container')}>
      <Button type="primary" disabled={loading} onClick={handleRetryLogin}>
          Retry Login
        </Button>
      </Row>
    </DefaultLayout>
    )}
    </>
  )
  
}

export default compose(withApollo)(AzureRedirectHandler)
