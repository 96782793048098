import React from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames/bind'
import styles from './Header.scss'
import { Icon } from 'antd'

import { Link } from 'react-router-dom'
import Logout from 'components/Logout'
const cx = classNames.bind(styles)

const HEADER_CONTENT_RIGHT_ID = 'header-content--right'
const HEADER_CONTENT_LEFT_ID = 'header-content--left'

export const HeaderContentRight = ({ children }) =>
  document.getElementById(HEADER_CONTENT_RIGHT_ID) &&
  createPortal(children, document.getElementById(HEADER_CONTENT_RIGHT_ID))

export const HeaderContentLeft = ({ children }) =>
  document.getElementById(HEADER_CONTENT_LEFT_ID) &&
  createPortal(children, document.getElementById(HEADER_CONTENT_LEFT_ID))

export const Header = () => {
  return (
    <header className={cx('header')}>
      <div className={cx('header__left')}>
        <div id={HEADER_CONTENT_LEFT_ID} />
      </div>
      <div className={cx('header__center')}>
        <Link to={`/`}>
          <img
            className={cx('header__logo')}
            src="/media/string-m.svg"
            alt="Header logo"
          />
        </Link>
      </div>
      <div className={cx('header__right')}>
        <Logout/>
        <Icon type="dash" />
      </div>
    </header>
  )
}

export default Header
