import React from 'react'
import { Container, Row, Col } from 'shared-components'
import { Link, withRouter } from 'react-router-dom'

import Card from 'components/Card'
import FourZeroFourHead from '../FourZeroFourHead'
import VirusImg from './assets/404-virus.png'

const handleGoBack = history => () => {
  history.location.key ? history.goBack() : history.push('/')
}

const FourZeroFourView = ({ history }) => [
  <FourZeroFourHead key="head" />,
  <Container key="content">
    <Card style={{ padding: '48px 72px' }}>
      <Row className="flex flex-align-center">
        <Col xs={5} className="text-center">
          <img alt="Page Not Found" src={VirusImg} style={{ width: '90%' }} />
        </Col>
        <Col xs={7} className="pl-32">
          <div className="ml-12">
            <h1 className="fs-48 ls-tight mb-4">Page Not Found</h1>
            <div className="fs-28 fw-300 lh-1-4">
              Sorry, the page could not be found. Visit the{' '}
              <Link className="fw-700 inline-block" to="/">
                homepage
              </Link>{' '}
              or{' '}
              <div
                className="fw-700 link inline-block"
                onClick={handleGoBack(history)}
              >
                go back
              </div>
              .
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  </Container>
]

export default withRouter(FourZeroFourView)
