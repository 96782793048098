import React, { Fragment } from 'react'
import classNames from 'classnames/bind'
import styles from './Logout.scss'
import { Icon, Tooltip } from 'shared-components'
import { removeBearerToken } from 'utils/localStorageUtils'
import { Mutation } from 'react-apollo'
import LOGOUT_MUTATION from 'apollo/mutations/Logout.gql'

const cx = classNames.bind(styles)

const Logout = ({ title, description, showText }) => {
  const handleLogout = async logout => {
    try {
      await logout()
    } catch {}
    removeBearerToken()
    window.close()
  }

  return (
    <Mutation mutation={LOGOUT_MUTATION}>
      {logout => {
        if (showText) {
          return (
            <Fragment>
               <div onClick={() => handleLogout(logout)}>
               <span> Logout </span>
                <Icon
                  library="antd"
                  type="logout"
                  style={{ fontSize: showText ? 18 : 16, cursor: 'pointer' }}
                  className={cx('header__logout__icon')}
                />
                </div>
            </Fragment>
          )
        }
        return (
          <Tooltip title="Logout" placement="bottom">
            <Icon
              library="antd"
              type="logout"
              style={{ fontSize: showText ? 18 : 16, cursor: 'pointer' }}
              className={cx('header__logout__icon')}
              onClick={() => handleLogout(logout)}
            />
          </Tooltip>
        )
      }}
    </Mutation>
  )
}
export default Logout
