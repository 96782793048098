import ExtendableError from 'es6-error'
export const extractErrorMessage  = error => {
         if (error && error.length) {
           try {
             return (
               error[0].extensions &&
               error[0].extensions.exception &&
               error[0].extensions.exception.data &&
               error[0].extensions.exception.data.errorKey
             )
           } catch (e) {
             return 'UNKNOWN'
           }
         } else {
           return 'UNKNOWN'
         }
       }

export default class CustomValidationError extends ExtendableError {
  constructor(message, error) {
    super(message)
    this.message = message
    this.errorCode = extractErrorMessage(error)
    this.errorType = 'CUSTOM_FE_VALIDATION'
  }
}

export const  getErrorMessage = (code) => {
  switch (code) {
    case 'ERROR_UNABLE_TO_OBTAIN_ACCESS_TOKEN_FROM_AUTHORIZATION_CODE':
    case 'ERROR_UNABLE_TO_RETRIEVE_MEMBER_GROUPS':
    case 'ERROR_UNABLE_TO_RETRIEVE_USER_PROFILE':
    case 'REQUEST_TIMEOUT':
      return 'Looks like the request is taking to long to complete, this can be caused by either poor connectivity or an error with our servers. Please try again.'
    default:
      return `Error Occurred : ${code}`
  }
}