import React from 'react'
import { Container, Row, Col } from 'shared-components'
import Card from 'components/Card'
import BRB_IMAGE from './brb-mercrobe.png'
import DefaultLayout from 'layouts/DefaultLayout'

export default () => (
  <DefaultLayout>
    <div className="flex flex-align-center justify-content-center width-100 height-100">
      <Container>
        <Card style={{ padding: '72px 84px' }}>
          <Row className="flex-align-center">
            <Col xs={5} className="text-center">
              <img
                alt="app error has occurred"
                src={BRB_IMAGE}
                className="width-90"
              />
            </Col>
            <Col xs={7}>
              <div className="ml-36">
                <h1 className="fs-96 fw-400 lh-1 mb-12">Oops!</h1>
                <p className="fs-36 fw-300 mb-48">Something went wrong.</p>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  </DefaultLayout>
)
