import React from 'react'
import FourZeroFour from '../FourZeroFour/components/FourZeroFourView'
import DownloadError from './DownloadError'
import { useLocation } from 'react-router-dom'

export default () => {
  const locInfo = useLocation()

  const DisplayPage =
    locInfo.search === '?code=NO_DATA_TO_EXPORT' ? DownloadError : FourZeroFour
  return <DisplayPage />
}
