import React, { Fragment } from 'react'
import { Button } from 'shared-components'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames/bind'
import { loadBearerToken, loadLogonId } from 'utils/localStorageUtils'
import DefaultLayout from 'layouts/DefaultLayout'
import styles from './ThirdPartyTable.scss'
import DatePickerFormField from 'components/DatePickerFormField'
import { Form } from 'react-final-form'
import moment from 'moment'
import DateRangeErrorModal from 'components/DateRangeErrorModal'
import { Helmet } from 'react-helmet'

const cx = classNames.bind(styles)

const ThirdPartyTool = () => {
  const [dateErrors, setDateErrors] = React.useState([])
  const search = values => {
    const createdfromdate =
      values['createdFromDate'] instanceof moment &&
      values['createdFromDate'].startOf('day').valueOf()
    const createdtodate =
      values['createdToDate'] instanceof moment &&
      values['createdToDate'].endOf('day').valueOf()
    let errors = []
    if (!createdfromdate || !createdtodate) {
      errors.push('Created From and Created To dates must be selected.')
    } else if (createdfromdate > createdtodate) {
      errors.push(
        'Created start date must be greater than completed start date.'
      )
    }
    if (errors.length) {
      setDateErrors(errors)
    } else {
      window.open(
        `/download-file?&bearerToken=${loadBearerToken()}&logonId=${loadLogonId()}&createdfromdate=${createdfromdate}&createdtodate=${createdtodate}`
      )
    }
  }
  if(!loadLogonId()){
    window.location.href = `${window.location.origin}/dashboard?nextAccess=Order Source Report`
    return ''
  }

  const closeErrorModal = () => setDateErrors([])
  return (
    <Fragment>
      <DefaultLayout>
          <Helmet>
            <title>Order Source Report</title>
            <meta name="description" content="Order Source Report"  />
          </Helmet>
        <div className={cx('third-party-form-wrapper')}>
          <Form onSubmit={search}>
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                autocomplete="off"
                className={cx('third-party-form-field')}
              >
                <div className={cx('third-party-lable')}>
                  Order Source Report
                </div>
                <div className={cx('third-party-content')}>
                  <div className={cx('third-party-date-range')}>
                    <DatePickerFormField
                      name="createdFromDate"
                      wrapperClass={cx('users-filter-form-field')}
                      placeholder="Created From"
                    />

                    <DatePickerFormField
                      name="createdToDate"
                      wrapperClass={cx('users-filter-form-field')}
                      placeholder="Created To"
                    />
                  </div>

                  <div className={cx('third-party-download')}>
                    <Button htmlType="submit" className={cx('header__export')}>
                      <FormattedMessage
                        id={'DOWNLOAD'}
                        defaultMessage={'Download'}
                      />
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      </DefaultLayout>
      <DateRangeErrorModal onOk={closeErrorModal} errors={dateErrors} />
    </Fragment>
  )
}
export default ThirdPartyTool
