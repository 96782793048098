import React from 'react'
import PropTypes from 'prop-types'
import { LocaleProvider } from 'shared-components'
import { ApolloProvider } from 'react-apollo'
import { IntlProvider } from 'react-intl'

import client from 'utils/apolloClient'
import ErrorBoundary from 'components/ErrorBoundary'
import AppError from 'components/AppError'
import Router from 'routes'

const AppContainer = ({ children, apolloClient }) => (
  <ErrorBoundary renderError={<AppError />}>
    <ApolloProvider client={client}>
      <IntlProvider messages={{}} locale="en">
        <LocaleProvider>
          <div className="app-root" style={{ height: '100%' }}>
            <Router />
          </div>
        </LocaleProvider>
      </IntlProvider>
    </ApolloProvider>
  </ErrorBoundary>
)

AppContainer.propTypes = {
  children: PropTypes.node
}

export default AppContainer
