import React from 'react'
import { Container } from 'shared-components'
import { withRouter } from 'react-router-dom'

import Card from 'components/Card'
import DownloadErrorHead from './components/DownloadErrorHead'

const DownloadError = ({ history }) => [
  <DownloadErrorHead key="head" />,
  <Container key="content">
    <Card style={{ padding: '48px 72px', textAlign: 'center' }}>
      <div className="ml-12">
        <div className="fs-28 fw-300 lh-1-4">
          No records found which meet your search criteria.
        </div>
      </div>
    </Card>
  </Container>
]

export default withRouter(DownloadError)
