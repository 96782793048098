import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  state = { error: false }

  componentDidCatch(error, info) {
    if (typeof this.props.onError === 'function') {
      this.props.onError(error)
    }
    this.setState({ error })
  }

  render() {
    const { error } = this.state
    const { renderError } = this.props
    if (error) {
      return typeof renderError === 'function'
        ? renderError(error)
        : renderError
    }
    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  renderError: error => <div>Sorry, an errors has occured.</div>
}

ErrorBoundary.propTypes = {
  renderError: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onError: PropTypes.func
}

export default ErrorBoundary
