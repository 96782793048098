import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './DefaultLayout.scss'
import Header from 'components/Header'
import ErrorBoundary from 'components/ErrorBoundary'
import AppError from 'components/AppError'

const cx = classNames.bind(styles)

const propTypes = {
  children: PropTypes.node
}

const DefaultLayout = ({ children }) => (
  <div className={cx('default-layout')}>
    <div className={cx('default-layout__header')}>
      <Header />
    </div>
    <div className={cx('default-layout__content')}>
      <ErrorBoundary renderError={<AppError />}>{children}</ErrorBoundary>
    </div>
  </div>
)

DefaultLayout.propTypes = propTypes

export default DefaultLayout
